import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useValue } from '../../components/context/ContextProvider'
import axios from '../../util/axios'

const Logout = () => {

    let navigate = useNavigate();

    const {
        state: { currentUser },
        dispatch
    } = useValue()

    useEffect(() => {
        axios.post(`/api/logout`)
            .then(() => {
                dispatch({ type: 'UPDATE_USER', payload: {} })
                navigate('/login');
            })
    }, []);


    return (
        <></>
    )
}

export default Logout