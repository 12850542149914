import { Circle, HighlightOff, Send } from '@mui/icons-material';
import { Button, Card, CardContent, Checkbox, CircularProgress, Grid, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Chip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useRef } from 'react'
import { useValue } from '../../components/context/ContextProvider';
import useFetch from '../../util/useFetch'
import axios from '../../util/axios'
import { Link, useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Users = () => {

    const { dispatch } = useValue()
    const { data, isLoading, error, reFetch } = useFetch('/api/users')
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [dataEdit, setdataEdit] = React.useState({});
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const [liveChecked, setLiveChecked] = React.useState(0);
    const [userId, setUserId] = React.useState(0);


    const handleOpen = () => setOpen(true);
    const navigate = useNavigate()

    const nameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()

    const nameEditRef = useRef()
    const passwordEditRef = useRef()
    const passwordConfirmEditRef = useRef()
    const liveEditRef = useRef()

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpen(false);
            setFormButtonLoading(false)
        }
    };

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        //send the registration form
        const name = nameRef.current.value
        const email = emailRef.current.value
        const password = passwordRef.current.value
        const password_confirmation = passwordConfirmRef.current.value

        if (password !== password_confirmation) {
            dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: "Passwords don't match" } })
            return
        }

        setFormButtonLoading(true)
        await axios.post(`/api/user/create`, {
            name,
            email: email,
            password,
            password_confirmation
        })
            .then(res => {
                reFetch(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'New user created successfully' } })
                setOpen(false);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    const handleCloseEdit = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpenEdit(false);
            setdataEdit({})
            setFormButtonLoading(false)
        }
    };

    const handleBackdropClickEdit = (event) => {
        event.stopPropagation();
        return false;
    };

    const handleOpenEdit = async (id) => {
        let userData = data.find((userId) => userId.id === id);
        setdataEdit(userData);
        (userData.live === 1) ? setLiveChecked(1) : setLiveChecked(0)
        setUserId(id);
        setOpenEdit(true);
    }

    const handleSubmitEdit = async (e) => {
        e.preventDefault()

        const nameEdit = nameEditRef.current.value
        const passwordEdit = passwordEditRef.current.value
        const password_confirmationEdit = passwordConfirmEditRef.current.value

        if ((passwordEdit !== '' || password_confirmationEdit !== '') && passwordEdit !== password_confirmationEdit) {
            dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: "Passwords don't match" } })
            return
        }

        await axios.post(`/api/user/update/${userId}`, {
            name: nameEdit,
            password: passwordEdit,
            password_confirmation: password_confirmationEdit,
            live: liveChecked
        })
            .then(res => {
                reFetch(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'User updated successfully' } })
                setOpenEdit(false);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false)
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)

        //console.log(liveChecked)
    }

    const handleChange = () => {
        if (liveChecked === 1) {
            setLiveChecked(0)
        } else {
            setLiveChecked(1)
        }
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Users
                                        </Typography>
                                        <Button variant="contained" onClick={handleOpen}>Create New</Button>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                                                    <TableHead sx={{ backgroundColor: "inherit" }}>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                                                Email
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Live
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Permissions
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map((row) => (
                                                            <TableRow key={row.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="left">{row.name}</TableCell>
                                                                <TableCell align="left">
                                                                    {row.email}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {row.live === 1 ? (<Chip label='Yes' color='success' />) : (<Chip label='No' color='secondary' />)}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Link to={`/users/permissions/${row.id}`}><Button>Permissions</Button></Link>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Button onClick={() => handleOpenEdit(row.id)}>Edit</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid >
                                </Box>
                            </CardContent>
                        </Card>


                        <Modal
                            open={open}
                            onClose={handleClose}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleClose} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>

                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}

                                >
                                    <form onSubmit={handleSubmit}>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5">New User</Typography>
                                        </Grid>
                                        <div>
                                            <TextField label="Name" type="text" size="small" inputRef={nameRef} />
                                            <TextField label="Email" size="small" inputRef={emailRef} />
                                            <TextField label="Password" type="password" size="small" inputRef={passwordRef} />
                                            <TextField label="Confirm Password" type="password" size="small" inputRef={passwordConfirmRef} />
                                        </div>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="primary" endIcon={<Send />} disabled={formButtonLoading}>Create</Button>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>



                        <Modal
                            open={openEdit}
                            onClose={handleCloseEdit}
                            onBackdropClick={handleBackdropClickEdit}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleCloseEdit} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>
                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}

                                >
                                    <form onSubmit={handleSubmitEdit}>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5">Edit User</Typography>
                                        </Grid>
                                        <div>
                                            <TextField label="Name" defaultValue={dataEdit.name} type="text" size="small" inputRef={nameEditRef} />
                                            <TextField label="Email" defaultValue={dataEdit.email} disabled size="small" />
                                            <TextField label="Password" type="password" size="small" inputRef={passwordEditRef} />
                                            <TextField label="Confirm Password" type="password" size="small" inputRef={passwordConfirmEditRef} />
                                            <Checkbox value="1" checked={liveChecked === 1} onChange={handleChange} />Live
                                        </div>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="primary" endIcon={<Send />} disabled={formButtonLoading}>Update</Button>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default Users