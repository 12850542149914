import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { useNavigate } from 'react-router-dom';
import { HighlightOff, Send } from '@mui/icons-material';
import { useValue } from '../../components/context/ContextProvider';
import axios from '../../util/axios';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Index = () => {
    const { data, isLoading, error, reFetch } = useFetch('/api/stories')
    const {
        state: { siteUrl },
        dispatch
    } = useValue()

    // const [photoGal, setPhotoGal] = React.useState([]);
    const [file, setFile] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const [imageSelected, setFormImageSelected] = React.useState(0);

    const [galType, setGalType] = React.useState(2);

    const nameRef = useRef()

    const navigate = useNavigate()


    const handleOpen = () => setOpen(true);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpen(false);
            setFormButtonLoading(false)
        }
    };

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        //send the registration form
        const name = nameRef.current.value

        const formData = new FormData();
        formData.append("name", name);
        // formData.append("images[]", file);
        file.map((image) => {
            formData.append("images[]", image);
        });

        console.log("file")
        console.log(file)
        console.log("formData.images")
        console.log(formData)


        if (name == "") {
            dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: 'Name fields is required.' } })
            return
        }

        setFormButtonLoading(true)
        await axios.post(`/api/story/create`, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                reFetch(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'New Story created successfully' } })
                setFile([]);
                setOpen(false);
            })
            .catch((err) => {
                setFormButtonLoading(false)
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFile([]);
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    const handleLocationChange = (event) => {
        setGalType(event.target.value)
    }

    const selectImageHandler = (e) => {
        setFile([]);
        const all_mages = [...e.target.files];
        all_mages.forEach((img) => {
            // return img;
            setFile((prevState) => [...prevState, img])
        })

        setFormImageSelected(all_mages.length)
        console.log(file)
    }

    const liveStory = async (id, live) => {
        let newLive;
        (live == 1) ? newLive = 0 : newLive = 1;
        setFormButtonLoading(true)
        await axios.post(`/api/story/update`, { id: id, live: newLive })
            .then(res => {
                reFetch(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Story updated successfully' } })
            })
            .catch((err) => {
                setFormButtonLoading(false)
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5">
                                        Photo Stories
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: "center", marginBottom: 3 }}>
                                            <Button variant="contained" onClick={handleOpen}>Create New</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        {(data.map(story => (
                                            <Grid item xs={6} md={3} key={story.id}>
                                                <Paper sx={{ display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        <img src={`${siteUrl}uploads/stories/${story.pic_year}/${story.pic_month}/thumb/${story.pic_name}_m${story.pic_ext}`} alt="" />
                                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 2, marginBottom: 2 }}>{(story.live === 1) ? <Button variant="contained" color="success" onClick={e => liveStory(story.id, story.live)} disabled={formButtonLoading}>Hide it</Button> : <Button variant="contained" color="error" onClick={e => liveStory(story.id, story.live)} disabled={formButtonLoading}>Show it</Button>}
                                                        </Grid>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        )))}
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>


                        <Modal
                            open={open}
                            onClose={handleClose}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleClose} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>

                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}

                                >
                                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5">Add New Photo Story</Typography>
                                        </Grid>
                                        <div>
                                            <FormControl fullWidth>
                                                <TextField label="Title" type="text" size="small" inputRef={nameRef} />
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    sx={{ margin: 1 }}
                                                    color="error"
                                                >
                                                    Select Image
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        hidden
                                                        onChange={selectImageHandler}
                                                        multiple
                                                    />
                                                </Button>
                                                <Typography variant="caption" sx={{ textAlign: "center" }} color="initial">{(imageSelected > 0) ? imageSelected + " image(s) selected" : ""}</Typography>
                                            </FormControl>
                                        </div>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="success" endIcon={<Send />} disabled={formButtonLoading}>Upload</Button>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>


                    </>
                )
            }
        </>
    )
}

export default Index