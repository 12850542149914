import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ContextProvider, { useValue } from './components/context/ContextProvider';
import Authenticated from './Layouts/authenticated';
import Guest from './Layouts/guest';
import Login from './Pages/Auth/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Settings from './Pages/Setting/Settings';
import "@fontsource/roboto";
import Users from './Pages/Users/Users';
import Logout from './Pages/Auth/Logout';
import Userpermissions from './Pages/Users/Userpermissions';
import Adverts from './Pages/Adverts/Index';
import EditAds from './Pages/Adverts/Edit';
import Galleries from './Pages/Gallery/Index';
import EditGallery from './Pages/Gallery/EditGallery';
import Stories from './Pages/Stories/Index';
import Comments from './Pages/Comments/Index';
import NewsDhi from './Pages/News/NewsDhi';
import EditNewsDhivehi from './Pages/News/EditNewsDhivehi';
import NewsEng from './Pages/News/NewsEng';
import EditNewsEnglish from './Pages/News/EditNewsEnglish';
import Others from './Pages/Others/Others';

import './fonts/MVWaheed.otf';
import './fonts/MVEamaanXP.ttf';
import './fonts/MVFaseyha.otf';

import './style.css'

let userData = JSON.parse(localStorage.getItem('currentuser_mf'));
console.log(userData)
if (userData == 'undefined' || userData == null) {
  userData = [];
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Guest />}>
            <Route index element={<Login />}></Route>
          </Route>
          <Route path="logout" element={<Guest />}>
            <Route index element={<Logout />}></Route>
          </Route>
          <Route path="dashboard" element={<Authenticated />}>
            <Route index element={<Dashboard />}></Route>
          </Route>
          <Route path="settings" element={<Authenticated />}>
            <Route index element={userData[0]?.permissions.includes("settings-update") ? (<Settings />) : (<Navigate replace to={"/logout"} />)}></Route>
          </Route>
          <Route path="adverts" element={<Authenticated />}>
            <Route index element={userData[0]?.permissions.includes("advert-view") ? (<Adverts />) : (<Navigate replace to={"/logout"} />)}></Route>
            <Route path="/adverts/:id" element={userData[0]?.permissions.includes("advert-update") ? (<EditAds />) : (<Navigate replace to={"/logout"} />)}></Route>
          </Route>
          <Route path="galleries" element={<Authenticated />}>
            <Route index element={<Galleries />}></Route>
            <Route path="/galleries/gallery/:id" element={<EditGallery />}></Route>
          </Route>
          <Route path="stories" element={<Authenticated />}>
            <Route index element={userData[0]?.permissions.includes("info-graphic-view") ? (<Stories />) : (<Navigate replace to={"/logout"} />)}></Route>
          </Route>
          <Route path="comments" element={<Authenticated />}>
            <Route index element={userData[0]?.permissions.includes("news-dhi-view") ? (<Comments />) : (<Navigate replace to={"/logout"} />)}></Route>
          </Route>
          <Route path="news-dhi" element={<Authenticated />}>
            <Route index element={userData[0]?.permissions.includes("news-dhi-view") ? (<NewsDhi />) : (<Navigate replace to={"/logout"} />)}></Route>
            <Route path="/news-dhi/edit/:id" element={userData[0]?.permissions.includes("news-dhi-update") ? (<EditNewsDhivehi />) : (<Navigate replace to={"/logout"} />)}></Route>
          </Route>
          <Route path="news-eng" element={<Authenticated />}>
            <Route index element={userData[0]?.permissions.includes("news-eng-view") ? (<NewsEng />) : (<Navigate replace to={"/logout"} />)}></Route>
            <Route path="/news-eng/edit/:id" element={userData[0]?.permissions.includes("news-eng-update") ? (<EditNewsEnglish />) : (<Navigate replace to={"/logout"} />)}></Route>
          </Route>
          <Route path="users" element={<Authenticated />}>
            <Route index element={<Users />}></Route>
            <Route path="/users/permissions/:id" element={<Userpermissions />}></Route>
          </Route>

          <Route path="others/:id" element={<Authenticated />}>
            <Route index element={<Others />}></Route>
          </Route>
          {/* <Route path="news" element={<Authenticated />}>
          <Route index element={<PostsAll />}></Route>
          <Route path="/news/create" element={<PostsCreate />}></Route>
          <Route path="/news/edit/:id" element={<PostsEdit />}></Route>
          <Route path="/news/view/:id" element={<PostsView />}></Route>
        </Route> */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode >
);
