import React, { useMemo, useRef } from 'react';
import { Button, Card, CardContent, CircularProgress, FormControl, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { Send } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../util/axios';
import { useValue } from '../../components/context/ContextProvider';
import JoditEditor from 'jodit-react';

const edit_news = "api/news/edit/dhivehi/";
const update_news = "api/news/update/dhivehi/";

const page_title = "thaana_heading";
const dhiv_label_class = "thaana_label"
const dhiv_class = "mf_texts thaana_admin"

const main_head = "އަސްލު ސުރުހީ";
const sub_head = "ހޯމްޕޭޖް ގެ ސުރުހީ";

const EditNewsDhivehi = ({ placeholder }) => {
    const params = useParams();
    const { data, isLoading, error } = useFetch(`${edit_news}${params.id}`)
    const [formButtonLoading, setFormButtonLoading] = React.useState(false)
    const [file, setFile] = React.useState([]);
    const [imageSelected, setFormImageSelected] = React.useState(0);
    const [fileSocial, setFileSocial] = React.useState([]);
    const [imageSelectedSocial, setFormImageSelectedSocial] = React.useState(0);

    const [content, setContent] = React.useState('');

    const navigate = useNavigate()

    const {
        state: { siteUrl },
        dispatch
    } = useValue()

    const mainHeadingRef = useRef();
    const subHeadingRef = useRef();
    const authorRef = useRef();
    const categoryRef = useRef();
    const excerptRef = useRef();
    const tagRef = useRef();
    const captionRef = useRef();
    const liveRef = useRef();
    const detailRef = useRef();



    const config = useMemo(
        () => ({
            readonly: false,
            direction: 'rtl',
            editorCssClass: 'thaana_admin',
        }),
        []
    );

    const selectImageHandler = (e) => {
        setFile([]);
        const all_mages = [...e.target.files];
        all_mages.forEach((img) => {
            // return img;
            setFile((prevState) => [...prevState, img])
        })

        setFormImageSelected(all_mages.length)
        console.log(all_mages.length)
    }

    const selectImageHandlerSocial = (e) => {
        setFileSocial([]);
        const all_mages = [...e.target.files];
        all_mages.forEach((img) => {
            // return img;
            setFileSocial((prevState) => [...prevState, img])
        })

        setFormImageSelectedSocial(all_mages.length)
        console.log(all_mages.length)
    }

    const submitChanges = async (e) => {
        e.preventDefault()
        setFormButtonLoading(true)

        const mainHeading = mainHeadingRef.current.value;
        const homeHeading = subHeadingRef.current.value;
        const author = authorRef.current.value;
        const category = categoryRef.current.value;
        const excerpt = excerptRef.current.value;
        const tag = tagRef.current.value;
        const caption = captionRef.current.value;
        const live = liveRef.current.value;
        const detail = detailRef.current.value;

        const formData = new FormData();
        formData.append("mainHeading", mainHeading);
        formData.append("homeHeading", homeHeading);
        formData.append("author", author);
        formData.append("category", category);
        formData.append("excerpt", excerpt);
        formData.append("tag", tag);
        formData.append("caption", caption);
        formData.append("live", live);
        formData.append("detail", detail);
        formData.append("id", params.id);

        file.map((image) => {
            formData.append("images[]", image);
        });

        fileSocial.map((imageSoci) => {
            formData.append("imagesSocial[]", imageSoci);
        });


        await axios.post(update_news, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                setFormImageSelected(0)
                setFormImageSelectedSocial(0)
                setFile([])
                setFileSocial([])
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'News updated successfully' } })
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }


    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ padding: 2 }}>
                                        <div className={page_title}>{data[0].title_long}</div>
                                    </Typography>
                                    <Grid container spacing={2} sx={{ padding: 2 }}>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{ marginBottom: 2 }}>
                                                <div className={dhiv_label_class}>{main_head}</div>
                                                <div><input ref={mainHeadingRef} defaultValue={data[0].title_long} className={dhiv_class} type="text" /></div>
                                            </Box>
                                            <Box sx={{ marginBottom: 2 }}>
                                                <div className={dhiv_label_class}>{sub_head}</div>
                                                <div><input ref={subHeadingRef} defaultValue={data[0].title_home} className={dhiv_class} type="text" /></div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ padding: 2 }}>
                                        <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Author</div>
                                                <div><input ref={authorRef} defaultValue={data[0].author} className={dhiv_class} type="text" /></div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Category</div>
                                                <div>
                                                    <select ref={categoryRef} className="mf_texts" defaultValue={data[0].art_type} >
                                                        {(data[1]?.map((item) => (
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        )))}
                                                    </select>
                                                </div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Excerpt</div>
                                                <div><textarea ref={excerptRef} defaultValue={data[0].excerpt} className={dhiv_class} /></div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Main Picture</div>
                                                <div>
                                                    <FormControl fullWidth>
                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            sx={{ marginTop: 1, marginBottom: 1 }}
                                                            color="error"
                                                        >
                                                            Featured Image
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                hidden
                                                                onChange={(e) => selectImageHandler(e)}

                                                            />
                                                        </Button>
                                                        <Typography variant="caption" sx={{ textAlign: "center" }} color="initial">{(imageSelected > 0) ? imageSelected + " image selected" : ""}</Typography>
                                                    </FormControl>
                                                </div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Caption</div>
                                                <div><textarea ref={captionRef} defaultValue={data[0].pic_caption} className={dhiv_class}></textarea></div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Social Website Picture</div>
                                                <div>
                                                    <FormControl fullWidth>
                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            sx={{ marginTop: 1, marginBottom: 1 }}
                                                            color="primary"
                                                        >
                                                            Social Website Picture
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                hidden
                                                                onChange={selectImageHandlerSocial}

                                                            />
                                                        </Button>
                                                        <Typography variant="caption" sx={{ textAlign: "center" }} color="initial">{(imageSelectedSocial > 0) ? imageSelectedSocial + " image selected" : ""}</Typography>
                                                    </FormControl>
                                                </div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Tag</div>
                                                <div><input ref={tagRef} defaultValue={data[0].first_tag} className={dhiv_class} type="text" /></div>
                                            </Box>

                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Live</div>
                                                <select ref={liveRef} className="mf_texts" defaultValue={data[0].live}>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </Box>


                                        </Grid>
                                        <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                                            <Box sx={{ marginBottom: 2 }}>
                                                <div>Detail</div>
                                                <JoditEditor
                                                    ref={detailRef}
                                                    value={data[0].detail}
                                                    config={config}
                                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                //onChange={newContent => setContent(newContent)}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid >
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                        <Button type="submit" variant="contained" color="success" onClick={submitChanges} endIcon={<Send />} disabled={formButtonLoading}>Update</Button>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card >
                    </>
                )
            }
        </>
    )
}

export default EditNewsDhivehi