import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button, Card, CardContent, Checkbox, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { Grain, Send, SupervisorAccount } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import { useValue } from '../../components/context/ContextProvider';
import axios from '../../util/axios'

const Userpermissions = () => {

    const params = useParams();
    const { data, isLoading, error, reFetch } = useFetch(`/api/user/permissions/${params.id}`)
    const { dispatch } = useValue()
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(true);
    const [userNewPermission, setUserNewPermission] = useState(data);

    const navigate = useNavigate()

    useEffect(() => {
        reFetch(data)
        console.log("data all")
        console.log(data)
        console.log(typeof data)
        if (data.length > 0) {
            setUserNewPermission(data[0]?.permissions)
        }
    }, [data]);

    const ifChecked = (val) => userNewPermission.some((i) => {
        //console.log(val)
        return i === val
    })

    // const ifChecked = (val) => {
    //     userNewPermission.forEach(function (month) {
    //         console.log(month);
    //     });
    //     //console.log("typeof userNewPermission");
    //     //console.log(typeof userNewPermission);
    // }

    const handleChange = (e) => {
        // console.log("data")
        // console.log(userNewPermission)
        setChecked(e.target.checked);
        // Destructuring
        const { value, checked } = e.target;
        //const { permissions } = userNewPermission;
        // console.log(`${value} is ${checked}`);
        // Case 1 : The user checks the box
        if (checked) {
            setUserNewPermission([...userNewPermission, value]);
        }
        // Case 2 : The user unchecks the box
        else {
            setUserNewPermission(userNewPermission.filter((e) => e !== value));
        }

        // console.log(userNewPermission)

    };

    const updatePermissions = async () => {

        console.log(userNewPermission)

        setFormButtonLoading(true)
        await axios.post(`/api/user/permissions/${params.id}`, {
            permissions: userNewPermission
        })
            .then(res => {
                reFetch(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'User permission updated successfully' } })
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box role="presentation" sx={{ padding: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            // underline="hover"
                                            style={{ textDecoration: 'none', color: '#2152a4' }}
                                            to={`/users`}
                                        >
                                            <SupervisorAccount sx={{ mr: 0.5 }} fontSize="inherit" color="primary" />
                                            Users
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <Grain sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Permissions
                                        </Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ padding: 2 }}>
                                        Permissions ({data[0]?.user.name})
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 350 }} aria-label="simple table" size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Create
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                View
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Update
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Settings</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox disabled />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox disabled />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("settings-update")}
                                                                    value="settings-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">News Dhivehi</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("news-dhi-add")}
                                                                    value="news-dhi-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("news-dhi-view")}
                                                                    value="news-dhi-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("news-dhi-update")}
                                                                    value="news-dhi-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">News English</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("news-eng-add")}
                                                                    value="news-eng-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("news-eng-view")}
                                                                    value="news-eng-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("news-eng-update")}
                                                                    value="news-eng-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Adverts</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("advert-add")}
                                                                    value="advert-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("advert-view")}
                                                                    value="advert-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("advert-update")}
                                                                    value="advert-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: 'inherit' }}
                                                        >
                                                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>Teams, Players & Tournaments</TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Teams</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("team-add")}
                                                                    value="team-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("team-view")}
                                                                    value="team-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("team-update")}
                                                                    value="team-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Tournaments</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("tournament-add")}
                                                                    value="tournament-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("tournament-view")}
                                                                    value="tournament-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("tournament-update")}
                                                                    value="tournament-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">All Players</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("player-add")}
                                                                    value="player-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("player-view")}
                                                                    value="player-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("player-update")}
                                                                    value="player-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Team players</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("assign-player-add")}
                                                                    value="assign-player-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("assign-player-view")}
                                                                    value="assign-player-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("assign-player-update")}
                                                                    value="assign-player-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: 'inherit' }}
                                                        >
                                                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>Matches</TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Fixtures</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("fixtures-add")}
                                                                    value="fixtures-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("fixtures-view")}
                                                                    value="fixtures-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("fixtures-update")}
                                                                    value="fixtures-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Standings</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("standing-add")}
                                                                    value="standing-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("standing-view")}
                                                                    value="standing-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("standing-update")}
                                                                    value="standing-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Webcast</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("webcast-add")}
                                                                    value="webcast-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("webcast-view")}
                                                                    value="webcast-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("webcast-update")}
                                                                    value="webcast-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: 'inherit' }}
                                                        >
                                                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>Photos</TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Photo Gallery</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("photo-gallery-add")}
                                                                    value="photo-gallery-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("photo-gallery-view")}
                                                                    value="photo-gallery-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("photo-gallery-update")}
                                                                    value="photo-gallery-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Video Gallery</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("video-gallery-add")}
                                                                    value="video-gallery-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("video-gallery-view")}
                                                                    value="video-gallery-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("video-gallery-update")}
                                                                    value="video-gallery-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Infographics</TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("info-graphic-add")}
                                                                    value="info-graphic-add"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("info-graphic-view")}
                                                                    value="info-graphic-view"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Checkbox
                                                                    checked={ifChecked("info-graphic-update")}
                                                                    value="info-graphic-update"
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color: green[800],
                                                                        '&.Mui-checked': {
                                                                            color: green[600],
                                                                        },
                                                                    }} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="primary" onClick={updatePermissions} endIcon={<Send />} disabled={formButtonLoading}>Update Permissions</Button>
                                        </Grid>
                                    </Grid >
                                </Box>
                            </CardContent>
                        </Card>
                    </>
                )
            }
        </>
    )
}

export default Userpermissions