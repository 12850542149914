import React, { useEffect, useState } from 'react'
import { useValue } from '../../components/context/ContextProvider';
import useFetch from '../../util/useFetch';

const Dashboard = () => {

    const { data, isLoading, error, reFetch } = useFetch('/api/users')

    const {
        state: { currentUser }
    } = useValue()


    return (
        <div>Dashboard</div>
    )
}

export default Dashboard