import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { Link, useNavigate } from 'react-router-dom';
import { HighlightOff, Send } from '@mui/icons-material';
import { useValue } from '../../components/context/ContextProvider';
import axios from '../../util/axios';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Index = () => {
    const { data, isLoading, error, reFetch } = useFetch('/api/ads')
    const { dispatch } = useValue()
    const [value, setValue] = React.useState(0);

    const [adLive, setAdLive] = React.useState([]);
    const [adDeactive, setAdDeactive] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);

    const [location, setLocation] = React.useState(0);

    const nameRef = useRef()
    const linkRef = useRef()

    const navigate = useNavigate()



    useEffect(() => {
        if (data) {
            const adLve = data[0]?.filter((ad) => {
                return ad.live === 1
            })
            setAdLive(adLve)

            const adNotLve = data[0]?.filter((ad) => {
                return ad.live === 0
            })
            setAdDeactive(adNotLve)
        }
    }, [data])

    const handleOpen = () => setOpen(true);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpen(false);
            setFormButtonLoading(false)
        }
    };

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        //send the registration form
        const name = nameRef.current.value
        const link = linkRef.current.value

        if (location === 0 || name == "" || link == "") {
            dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: 'All fields are required.' } })
            return
        }

        setFormButtonLoading(true)
        await axios.post(`/api/ads/create`, {
            name,
            link: link,
            location: location
        })
            .then(res => {
                reFetch(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'New advert created successfully' } })
                setOpen(false);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value)
    }


    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5">
                                        Adverts
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: "center" }}>
                                            <Button variant="contained" onClick={handleOpen}>Create New</Button>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{ width: '100%' }}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                        <Tab label="All" {...a11yProps(0)} />
                                                        <Tab label="Active" {...a11yProps(1)} />
                                                        <Tab label="Deactivated" {...a11yProps(2)} />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel value={value} index={0}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                        Link
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Location
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Views
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        live
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {data[0].map((row) => (
                                                                    <TableRow
                                                                        key={row.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{row.name}</TableCell>
                                                                        <TableCell align="left">
                                                                            {row.link}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.loc}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.views.toLocaleString('en-US')}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.live === 1 ? (<Chip label='Yes' color='success' />) : (<Chip label='No' color='secondary' />)}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Link to={`/adverts/${row.id}`}><Button>Edit</Button></Link>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TabPanel>
                                                <TabPanel value={value} index={1}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                        Link
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Location
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Views
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        live
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {adLive?.map((row) => (
                                                                    <TableRow
                                                                        key={row.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{row.name}</TableCell>
                                                                        <TableCell align="left">
                                                                            {row.link}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.loc}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.views.toLocaleString('en-US')}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.live === 1 ? (<Chip label='Yes' color='success' />) : (<Chip label='No' color='secondary' />)}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Link to={`/adverts/${row.id}`}><Button>Edit</Button></Link>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TabPanel>
                                                <TabPanel value={value} index={2}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                        Link
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Location
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Views
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        live
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {adDeactive?.map((row) => (
                                                                    <TableRow
                                                                        key={row.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">{row.name}</TableCell>
                                                                        <TableCell align="left">
                                                                            {row.link}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.loc}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.views.toLocaleString('en-US')}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {row.live === 1 ? (<Chip label='Yes' color='success' />) : (<Chip label='No' color='secondary' />)}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Link to={`/adverts/${row.id}`}><Button>Edit</Button></Link>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TabPanel>
                                            </Box>
                                        </Grid>
                                    </Grid >
                                </Box>
                            </CardContent>
                        </Card>


                        <Modal
                            open={open}
                            onClose={handleClose}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleClose} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>

                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}

                                >
                                    <form onSubmit={handleSubmit}>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5">Add New Advert</Typography>
                                        </Grid>
                                        <div>
                                            <FormControl fullWidth><TextField label="Name" type="text" size="small" inputRef={nameRef} /></FormControl>
                                            <FormControl fullWidth><TextField label="Link" size="small" inputRef={linkRef} /></FormControl>
                                            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                                                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                                <Select
                                                    defaultValue=""
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Location"
                                                    value={location || ""}
                                                    onChange={handleLocationChange}
                                                >
                                                    {(data[1]?.map((item) => (
                                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                                    )))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="primary" endIcon={<Send />} disabled={formButtonLoading}>Create</Button>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>


                    </>
                )
            }
        </>
    )
}

export default Index