import React from 'react'
import { Button, Card, CardContent, CircularProgress, Grid, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { Link, useNavigate } from 'react-router-dom'
import { useValue } from '../../components/context/ContextProvider'
import { HighlightOff, Send } from '@mui/icons-material'
import axios from '../../util/axios'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const all_news = "/api/news/english";
const add_news = "/api/news/create/english";
const redirect_to_edit = "/news-eng/edit/";

const NewsEng = () => {

    const { data, isLoading, error } = useFetch(all_news)
    const [open, setOpen] = React.useState(false);
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const { dispatch } = useValue()
    const navigate = useNavigate()

    const handleOpen = () => setOpen(true);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpen(false);
            setFormButtonLoading(false)
        }
    };

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormButtonLoading(true)
        await axios.post(add_news)
            .then(res => {
                // console.log(res.data)
                navigate(redirect_to_edit + res.data)
                // reFetch(res.data)
                //console.log(res.data)
                // dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'New Gallery created successfully' } })
                setOpen(false);
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        News (Dhivehi)
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: "center" }}>
                                            <Button variant="contained" onClick={handleOpen}>Create New</Button>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                Main Heading
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                Home Page Heading
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                                                Author
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                                                Hits
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Edit</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map((item) => (
                                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={item.id}>
                                                                <TableCell align="left">
                                                                    {item.title_long}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.title_home}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.author}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {item.hits}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Link to={`${redirect_to_edit}${item.id}`}><Button>Edit</Button></Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid >
                                </Box>
                            </CardContent>
                        </Card>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleClose} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>
                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}

                                >
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5">Create A News</Typography>
                                        </Grid>
                                        <Box sx={{ textAlign: "center" }}>
                                            Are you sure you want to create a new story?
                                        </Box>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="primary" endIcon={<Send />} disabled={formButtonLoading}>Create</Button>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>
                    </>
                )
            }
        </>
    )
}

export default NewsEng