const reducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_LOGIN':
            return { ...state, openLogin: true }
        case 'CLOSE_LOGIN':
            return { ...state, openLogin: false }

        case 'CHANGE_THEM_DARK':
            return { ...state, isDarkTheme: true }

        case 'CHANGE_THEM_LIGHT':
            return { ...state, isDarkTheme: false }

        case 'START_WINDOW_LOADING':
            return { ...state, windowLoading: true }
        case 'END_WINDOW_LOADING':
            return { ...state, windowLoading: false }

        case 'UPDATE_PAGE_LOADING':
            return { ...state, loadingBar: action.payload }

        case 'UPDATE_ALERT':
            return { ...state, alert: action.payload }

        case 'UPDATE_COMMENT':
            return { ...state, unapprovedComments: action.payload }

        case 'UPDATE_USER':
            localStorage.setItem('currentuser_mf', JSON.stringify(action.payload))
            return { ...state, currentUser: action.payload }
        default:
            throw new Error('No matched action!')
    }
}

export default reducer