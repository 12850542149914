import React, { useEffect } from 'react'
import { Button, Card, CardContent, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import axios from '../../util/axios';
import { useValue } from '../../components/context/ContextProvider';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const { data, isLoading, error } = useFetch('/api/comments/dhivehi')
    const [commnts, setComments] = React.useState()
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const navigate = useNavigate()

    const {
        state: { siteUrl, unapprovedComments },
        dispatch
    } = useValue()

    useEffect(() => {
        setComments(data)
    }, [data])


    const deleteComment = async (id, nid) => {
        setFormButtonLoading(true)
        await axios.post(`/api/comment/dhivehi/delete`, {
            commentId: id,
            newsId: nid
        })
            .then(res => {
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Comment deleted successfully' } })
                dispatch({ type: "UPDATE_COMMENT", payload: res.data })
                console.log(unapprovedComments)
                const adLve = commnts.filter((cmnt) => {
                    return cmnt.id != id
                })
                setComments(adLve)
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    const approveComment = async (id, nid) => {
        setFormButtonLoading(true)
        await axios.post(`/api/comment/dhivehi/approve`, {
            commentId: id,
            newsId: nid
        })
            .then(res => {
                console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Comment approved successfully' } })
                dispatch({ type: "UPDATE_COMMENT", payload: res.data })
                const adLve = commnts.filter((cmnt) => {
                    return cmnt.id != id
                })
                setComments(adLve)
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Approve Comments
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}></TableCell>

                                                            <TableCell align="right">

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {commnts?.map((row) => (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                key={row.id}
                                                            >
                                                                <TableCell align="right">
                                                                    <div className="comments_dhi">{row.comments}</div>
                                                                    <div className="comments_dhi">-{row.name}-</div>
                                                                    <div className="mf-thaana-text-box comments_dhi">{row.news}</div>
                                                                </TableCell>

                                                                <TableCell align="right">
                                                                    <Button color="success" onClick={() => approveComment(row.id, row.news_id)} disabled={formButtonLoading}>Approve</Button>
                                                                    <Button variant="contained" color="error" onClick={() => deleteComment(row.id, row.news_id)} disabled={formButtonLoading}>Delete</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid >
                                </Box>
                            </CardContent>
                        </Card>
                    </>
                )
            }
        </>
    )
}

export default Index