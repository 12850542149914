import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import { Autocomplete, Box, Card, CardContent, CircularProgress, Divider, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useValue } from '../../components/context/ContextProvider';
import useFetch from '../../util/useFetch';
import { HighlightOff, Send } from '@mui/icons-material';
import axios from '../../util/axios';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Settings = () => {

    const { dispatch } = useValue()
    const { data, isLoading, error, reFetch } = useFetch('/api/settings')
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const [selectedYear, setSelectedYear] = React.useState();
    const [selectedRangeOfTournament, setSelectedRangeOfTournament] = React.useState([]);
    const [selectedTournament, setSelectedTournament] = React.useState();
    const [selectedRangeOfRound, setSelectedRangeOfRound] = React.useState([]);
    const [selectedRound, setSelectedRound] = React.useState();

    const [updatedCurrentValue, setUpdatedCurrentValue] = React.useState([]);


    const fixAdminRef = useRef()
    const fixFrontRef = useRef()
    const standingAdminRef = useRef()
    const standingFrontRef = useRef()
    const playerAdminRef = useRef()
    const playerFrontRef = useRef()

    useEffect(() => {
        reFetch(data)
        console.log("data all")
        console.log(data)
        console.log(typeof data)
        if (data) {
            setUpdatedCurrentValue([data[4]?.title_en, data[4]?.year, data[0]?.stand_round])
        }
        console.log(updatedCurrentValue)
    }, [data]);


    const {
        state: { currentUser }
    } = useValue()

    const handleOpen = () => setOpen(true);


    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpen(false);
            //setFormButtonLoading(false)
        }
    };

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const fixAdmin = fixAdminRef.current.value
        const fixFront = fixFrontRef.current.value
        const standingAdmin = standingAdminRef.current.value
        const standingFront = standingFrontRef.current.value
        const playerAdmin = playerAdminRef.current.value
        const playerFront = playerFrontRef.current.value

        setFormButtonLoading(true)
        await axios.put(`/api/settings/update`, {
            fix_admin: fixAdmin,
            fix_front: fixFront,
            stand_admin: standingAdmin,
            stand_front: standingFront,
            player_admin: playerAdmin,
            player_front: playerFront
        })
            .then(res => {
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Settings upated successfully' } })
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    // const years = [...new Set(data[1]?.map((item) => item))];

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value)
        const newT = data[3].filter((tournmanent) => {
            return tournmanent.year === event.target.value
        })
        setSelectedRangeOfTournament(newT)
        console.log(selectedRangeOfTournament)
    }

    const handleTournamentChange = (event, value) => {
        setSelectedTournament(event.target.value)
        const newT = data[2].filter((tRound) => {
            return tRound.tournament_id === event.target.value
        })
        setSelectedRangeOfRound(newT)

        console.log(selectedRangeOfRound)
    };

    const handleRoundChange = (event, value) => {
        setSelectedRound(event.target.value)
        console.log(selectedRound)
    };

    const handleSubmit_modal = async (e) => {
        e.preventDefault()
        setFormButtonLoading(true)
        await axios.put(`/api/settings/update`, {
            stand_tourn: selectedTournament,
            stand_round: selectedRound
        })
            .then(res => {
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Data upated successfully' } })
                //get tournament name
                const tName = data[3].find((tournmanent) => {
                    return tournmanent.id === selectedTournament
                })
                setUpdatedCurrentValue([tName?.title_en, selectedYear, selectedRound])
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Settings
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>

                                            <TableContainer>
                                                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Admin View
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                                                Front View
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Fixtures</TableCell>
                                                            <TableCell align="center">
                                                                <TextField sx={{ maxWidth: 100 }} inputRef={fixAdminRef} defaultValue={data[0]?.fix_admin} size="small" />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <TextField sx={{ maxWidth: 100 }} inputRef={fixFrontRef} defaultValue={data[0]?.fix_front} size="small" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Standings</TableCell>
                                                            <TableCell align="center">
                                                                <TextField sx={{ maxWidth: 100 }} inputRef={standingAdminRef} defaultValue={data[0]?.stand_admin} size="small" />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <TextField sx={{ maxWidth: 100 }} inputRef={standingFrontRef} defaultValue={data[0]?.stand_front} size="small" />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Players</TableCell>
                                                            <TableCell align="center">
                                                                <TextField sx={{ maxWidth: 100 }} inputRef={playerAdminRef} defaultValue={data[0]?.player_admin} size="small" />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <TextField sx={{ maxWidth: 100 }} inputRef={playerFrontRef} defaultValue={data[0]?.player_front} size="small" />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" marginTop={6}>
                                                <Typography sx={{ fontWeight: 'bold' }}>Home Page Point Table</Typography>
                                            </Grid>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                                                <Typography >{updatedCurrentValue[0]} ({updatedCurrentValue[1]})</Typography>
                                                <Typography >Round/Group: {(updatedCurrentValue[2] == 555) ? "Overall" : "Round " + updatedCurrentValue[2]}</Typography>
                                                <Typography variant="h6" color="text.secondary" marginTop={6}>
                                                    <Button onClick={() => handleOpen()} variant="contained" color="error">Change</Button>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                            <Button type="submit" variant="contained" color="primary" endIcon={<Send />} disabled={formButtonLoading}>Update</Button>
                                        </Grid>
                                    </Grid >
                                </Box>
                            </form>
                        </CardContent>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleClose} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>

                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}
                                >

                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                        <Typography variant="h5">Select Point Table for Home Page</Typography>
                                    </Grid>
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: 2 }}>
                                        <InputLabel id="select-year">Select Year</InputLabel>
                                        <Select
                                            defaultValue=""
                                            labelId="select-year"
                                            id="year_select"
                                            label="Select Year"
                                            value={selectedYear || ''}
                                            onChange={handleYearChange}
                                        >
                                            <MenuItem value={data[1][0]}>{data[1][0]}</MenuItem>
                                            <MenuItem value={data[1][1]}>{data[1][1]}</MenuItem>
                                            <MenuItem value={data[1][2]}>{data[1][2]}</MenuItem>
                                        </Select>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                                        <InputLabel id="select-tournament">Select Tournament</InputLabel>
                                        <Select
                                            defaultValue=""
                                            labelId="select-tournament"
                                            id="tournament_select"
                                            label="Select Tournament"
                                            value={selectedTournament || ''}
                                            onChange={handleTournamentChange}
                                        >
                                            {selectedRangeOfTournament?.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title_en}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 2, marginBottom: 3 }}>
                                        <InputLabel id="select-round">Select Round or Group</InputLabel>
                                        <Select
                                            defaultValue=""
                                            labelId="select-round"
                                            id="round_select"
                                            label="Select Round or Group"
                                            value={selectedRound || ''}
                                            onChange={handleRoundChange}
                                        >
                                            {selectedRangeOfRound?.map((item) => (
                                                <MenuItem key={item.id} value={item.round}>{(item.round == 555) ? "Overall" : "Round " + item.round}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                        {(selectedRound && selectedTournament) ? <Button type="submit" onClick={handleSubmit_modal} variant="contained" color="primary" endIcon={<Send />} disabled={formButtonLoading}>Update</Button> : ""}
                                    </Grid>

                                </Box>
                            </Box>
                        </Modal>
                    </Card>
                </>
            )
            }
        </>
    )
}

export default Settings