import { useState, useEffect } from 'react';
import { createTheme, styled, ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Logout from '@mui/icons-material/Logout';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LightModeIcon from '@mui/icons-material/LightMode';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import ChatBubbleOutlinedIcon from '@mui/icons-material/ChatBubbleOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useValue } from '../components/context/ContextProvider';
import { Badge, Button, CircularProgress, LinearProgress, Menu, MenuItem, Switch } from '@mui/material';

import Sitemenu from './Sitemenu';
import useFetch from '../util/useFetch';
import Notification from '../components/Display/Notification';
import axios from '../util/axios';


// Define theme settings
const light = {
    palette: {
        mode: "light",
    },
};

const dark = {
    palette: {
        mode: "dark",
    },
};

//Theme color ends

const menuId = 'primary-search-account-menu';
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Authenticated = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(true);
    const [anchorElProfile, setAnchorElProfile] = useState(null);

    const navigate = useNavigate()

    const {
        state: { currentUser, isDarkTheme, windowLoading, loadingBar, unapprovedComments },
        dispatch
    } = useValue()

    const { data, isLoading, error } = useFetch('/api/user/me')
    // const { data: comments } = useFetch('api/comment/dhivehi/all')

    useEffect(() => {
        axios.get(`/api/comment/dhivehi/all`)
            .then(res => {
                dispatch({ type: "UPDATE_COMMENT", payload: res.data })
            })
    }, [])



    // console.log(data)
    // console.log(loading)
    //console.log("error " + error)


    //Dark theme
    const changeTheme = () => {
        isDarkTheme ? dispatch({ type: "CHANGE_THEM_LIGHT" }) : dispatch({ type: "CHANGE_THEM_DARK" })
    };
    //END of Dark theme


    const location = useLocation()
    const path = location.pathname

    const theme = useTheme();

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const openProfile = Boolean(anchorElProfile);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElProfile(null);
    }

    const logoutNow = () => {
        navigate('/logout')
    }

    const goToComment = () => {
        navigate('/comments')
    }

    return (
        <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
            {windowLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <AppBar position="fixed" open={open}>
                            <LinearProgress color="secondary" variant="determinate" value={loadingBar} sx={
                                {
                                    background: 'transparent',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'red',
                                        transition: 'none'
                                    }
                                }
                            } />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            marginRight: 5,
                                            ...(open && { display: 'none' })
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        Maldives Football
                                    </Typography>
                                    <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'block', sm: 'none' } }}>
                                        MF
                                    </Typography>
                                </Toolbar>
                                <Toolbar>
                                    {/* <Switch checked={isDarkTheme} onChange={changeTheme} /> */}
                                    <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={changeTheme}>
                                        <LightModeOutlinedIcon sx={{ display: !isDarkTheme ? 'none' : 'block' }} />
                                        <DarkModeOutlinedIcon sx={{ display: isDarkTheme ? 'none' : 'block' }} />
                                    </IconButton>
                                    <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={goToComment}>
                                        <Badge badgeContent={unapprovedComments} color="error">
                                            <ChatBubbleOutlinedIcon />
                                        </Badge>
                                    </IconButton>
                                    {/* <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        aria-label="account of current user"
                                        // aria-controls={menuId}
                                        aria-haspopup="true"
                                        color="inherit"
                                        sx={{ marginRight: '2px' }}
                                        id="basic-button"
                                        aria-controls={openProfile ? 'basic-menu' : undefined}
                                        aria-expanded={openProfile ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <AccountCircle />
                                    </IconButton>



                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorElProfile}
                                        open={openProfile}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                                        <MenuItem onClick={handleClose}>My account</MenuItem>
                                        <MenuItem onClick={logoutNow}>Logout</MenuItem>
                                    </Menu>


                                </Toolbar>
                            </Box>


                        </AppBar>
                        <Drawer variant="permanent" open={open}>
                            <DrawerHeader sx={{ color: 'white', display: 'flex', justifyContent: 'space-between', backgroundColor: !isDarkTheme ? '#1565c0' : '#000' }}>
                                <Typography sx={{ marginLeft: 2 }}>{currentUser.name}</Typography>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: 'white' }} /> : <ChevronLeftIcon sx={{ color: 'white' }} />}
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                            <Sitemenu open={open} />
                            <List>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        to="/logout"
                                        component={NavLink}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Logout />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>

                            </List>
                        </Drawer>
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <DrawerHeader />
                            <Notification></Notification>
                            <Outlet />
                        </Box>
                    </Box>
                </>
            )}
        </ThemeProvider>
    );
}


export default Authenticated