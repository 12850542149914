import React, { createElement } from 'react'
import { NavLink } from 'react-router-dom'
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
// import Micon from './Micons'
import { Dashboard, Settings, SupervisorAccount, Campaign, Collections, WebStories, Feed, ArticleOutlined, EmojiEvents, SportsHandball, Sports, Diversity3 } from '@mui/icons-material';
import { useValue } from '../components/context/ContextProvider';


const Sitemenu = ({ open }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const handleListItemClick = (index) => {
        setSelectedIndex(index);
    };

    const {
        state: { currentUser },
    } = useValue()


    const menu_items = [
        [
            {
                id: 1,
                name: "Dashboard",
                path: "/dashboard",
                permission: "",
                icon: "Dashboard"
            },
            {
                id: 2,
                name: "Settings",
                path: "/settings",
                permission: "settings-update",
                icon: "Settings"
            },
            {
                id: 3,
                name: "Adverts",
                path: "/adverts",
                permission: "advert-view",
                icon: "Campaign"
            }
        ],
        [
            {
                id: 4,
                name: "Dhivehi News",
                path: "/news-dhi",
                permission: "news-dhi-view",
                icon: "Feed"
            },
            {
                id: 5,
                name: "English News",
                path: "/news-eng",
                permission: "news-eng-view",
                icon: "ArticleOutlined"
            }
        ],
        [
            {
                id: 6,
                name: "Galleries",
                path: "/galleries",
                permission: "photo-gallery-view",
                icon: "Collections"
            },
            {
                id: 7,
                name: "Photo Stories",
                path: "/stories",
                permission: "info-graphic-view",
                icon: "WebStories"
            }
        ],
        [
            {
                id: 8,
                name: "Tournaments",
                path: "/others/Tournaments",
                permission: "tournament-view",
                icon: "EmojiEvents"
            },
            {
                id: 9,
                name: "Teams",
                path: "/others/Teams",
                permission: "team-view",
                icon: "Diversity3"
            },
            {
                id: 10,
                name: "Players",
                path: "/others/Players",
                permission: "player-view",
                icon: "SportsHandball"
            },
            {
                id: 11,
                name: "Matches",
                path: "/others/Matches",
                permission: "fixtures-view",
                icon: "Sports"
            }
        ],
        [
            {
                id: 12,
                name: "Users",
                path: "/users",
                permission: "user-view",
                icon: "SupervisorAccount"
            }
        ]
    ];
    return (
        <>
            {menu_items.map((items, i) => (
                <React.Fragment key={i}>
                    <List
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                                bgcolor: '#4dabf5',
                                '&, & .MuiListItemIcon-root': {
                                    color: 'inherit',
                                },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                                bgcolor: '#4dabf5',
                                '&, & .MuiListItemIcon-root': {
                                    color: 'inherit',
                                },
                            },
                        }}
                    >

                        {menu_items[i].map((item, index) => (
                            ((currentUser[0].permissions.includes(item.permission)) ?
                                <ListItem disablePadding sx={{ display: 'block' }} key={index}>
                                    <ListItemButton
                                        to={item.path}
                                        component={NavLink}
                                        sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                                        selected={selectedIndex === item.id}
                                        onClick={() => handleListItemClick(item.id)}
                                    >
                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                            {(() => {
                                                if (item.icon == "Dashboard") { return (<Dashboard />) }
                                                else if (item.icon == "Settings") { return (<Settings />) }
                                                else if (item.icon == "SupervisorAccount") { return (<SupervisorAccount />) }
                                                else if (item.icon == "Campaign") { return (<Campaign />) }
                                                else if (item.icon == "Collections") { return (<Collections />) }
                                                else if (item.icon == "WebStories") { return (<WebStories />) }
                                                else if (item.icon == "Feed") { return (<Feed />) }
                                                else if (item.icon == "ArticleOutlined") { return (<ArticleOutlined />) }
                                                else if (item.icon == "EmojiEvents") { return (<EmojiEvents />) }
                                                else if (item.icon == "SportsHandball") { return (<SportsHandball />) }
                                                else if (item.icon == "Sports") { return (<Sports />) }
                                                else if (item.icon == "Diversity3") { return (<Diversity3 />) }
                                            })()}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                                : "")
                        ))}
                    </List>
                    <Divider />
                </React.Fragment>
            ))
            }
        </>
    )
}

export default Sitemenu