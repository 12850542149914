import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useValue } from '../components/context/ContextProvider'
import { useNavigate } from 'react-router-dom'

const useFetch = (dataUrl) => {
    const navigate = useNavigate()
    const {
        state: { currentUser },
        dispatch
    } = useValue()

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const api = axios.create({
        baseURL: 'https://maldivesfootball.com',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true,
    })

    // api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


    // dispatch({ type: 'UPDATE_PAGE_LOADING', payload: 10 })

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();

        const fetchData = async (url) => {
            setIsLoading(true);
            let pecentages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            let randomPercent = pecentages[(Math.random() * pecentages.length) | 0];

            let increments = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
            let randomIncrements = increments[(Math.random() * increments.length) | 0];

            dispatch({ type: 'UPDATE_PAGE_LOADING', payload: randomPercent })


            dispatch({ type: 'UPDATE_PAGE_LOADING', payload: randomPercent + randomIncrements })
            //console.log('usestate call from fetch')

            dispatch({ type: 'UPDATE_PAGE_LOADING', payload: randomPercent + 2 * randomIncrements })
            try {
                dispatch({ type: 'UPDATE_PAGE_LOADING', payload: randomPercent + 3 * randomIncrements })
                const response = await api.get(url, {
                    CancelToken: source.token
                })
                if (isMounted) {
                    dispatch({ type: 'UPDATE_PAGE_LOADING', payload: randomPercent + 4 * randomIncrements })
                    setData(response.data)
                    console.log("response.data " + url)
                    console.log(response.data)
                    setError(null)
                    dispatch({ type: "END_WINDOW_LOADING" })
                    // setTimeout(() => setIsLoading(false), 2000)

                }
            } catch (err) {
                if (isMounted) {
                    setData([])
                    setError(err.message)
                    if (err.response.status === 401) {
                        navigate('/login')
                    }
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                    dispatch({ type: 'UPDATE_PAGE_LOADING', payload: 100 })
                    setTimeout(() => dispatch({ type: 'UPDATE_PAGE_LOADING', payload: 0 }), 500)
                    // dispatch({ type: "END_WINDOW_LOADING" })
                }
            }
        }

        fetchData(dataUrl)

        const cleanUp = () => {
            isMounted = false;
            source.cancel();
        }

        return cleanUp;

    }, [dataUrl]);

    function reFetch(data) {
        setData(data)
    }

    return { data, isLoading, error, reFetch }

}

export default useFetch