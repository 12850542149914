import { HighlightOff, Send } from '@mui/icons-material';
import { Grid, Box, Typography, Button, Modal, FormControl, Paper, Chip, CircularProgress } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useValue } from '../../components/context/ContextProvider'
import axios from '../../util/axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const style_edit = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const PhotoGalleryEdit = () => {

    const params = useParams();


    const {
        state: { siteUrl },
        dispatch
    } = useValue()

    const [imageData, setImageData] = React.useState([]);
    const [file, setFile] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [formButtonLoading, setFormButtonLoading] = React.useState(false);
    const [picLoading, setPicLoading] = React.useState(true);
    const [imageSelected, setFormImageSelected] = React.useState(0);
    const [imageForEdit, setImageForEdit] = React.useState([]);
    const [imageEditLoading, setImageEditLoading] = React.useState(false);
    const [dataEditingEng, setDataEditingEng] = React.useState("");
    const [dataEditingDhi, setDataEditingDhi] = React.useState("");
    const [dataEditingOrd, setDataEditingOrd] = React.useState(0);
    const [dataEditingLive, setDataEditingLive] = React.useState(0);
    const [photoId, setPhotoId] = React.useState(0);

    const navigate = useNavigate()
    const id = params.id

    useEffect(() => {
        setPicLoading(true)
        axios.get(`/api/gallery/photo/all/${id}`)
            .then(res => {
                setImageData(res.data)
                setPicLoading(false)
            })
        setPicLoading(false)
    }, [])


    const handleOpen = () => {
        setFile([]);
        setFormImageSelected(0)
        setOpen(true)
    };

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setOpen(false);
            setFile([]);
            setFormImageSelected(0)
            setFormButtonLoading(false)
        }
    };

    const handleBackdropClick = (event) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("id", id);
        // formData.append("images[]", file);
        file.map((image) => {
            formData.append("images[]", image);
        });

        console.log("file")
        console.log(file)
        console.log("formData.images")
        console.log(formData)


        if (id == "") {
            dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: 'ID is required.' } })
            return
        }

        setFormButtonLoading(true)
        await axios.post(`/api/gallery/photo/upload`, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                setImageData(res.data)
                //console.log(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'New Story created successfully' } })
                setFile([]);
                setFormImageSelected(0)
                setOpen(false);
            })
            .catch((err) => {
                setFormButtonLoading(false)
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFile([]);
                setFormImageSelected(0)
                setFormButtonLoading(false);
                //console.log(err.response.data.message)
            })
        setFormButtonLoading(false)
    }

    const selectImageHandler = (e) => {
        setFile([]);
        const all_mages = [...e.target.files];
        all_mages.forEach((img) => {
            // return img;
            setFile((prevState) => [...prevState, img])
        })

        setFormImageSelected(all_mages.length)
        console.log(file)
    }
    const handleCloseEdit = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            console.log("Clicked");
            setOpenEdit(false);
        }
    }

    const openEditModal = async (Pid) => {
        setPhotoId(Pid)
        setImageEditLoading(true)
        setOpenEdit(true)
        await axios.post(`/api/gallery/photo/edit`, { id: Pid })
            .then(res => {
                setImageForEdit(res.data)
                if (res.data.detail_en != "" || res.data.detail_en != null) {
                    setDataEditingEng(res.data.detail_en)
                } else {
                    setDataEditingEng("")
                }
                if (res.data.detail_dhi != "" || res.data.detail_dhi != null) {
                    setDataEditingDhi(res.data.detail_dhi)
                } else {
                    setDataEditingDhi("")
                }
                setDataEditingOrd(res.data.ord_pic)
                setDataEditingLive(res.data.live)
                setImageEditLoading(false)
            })
        setImageEditLoading(false)
    }

    const changeValue = (e, val) => {
        if (val == "detail_en") {
            setDataEditingEng(e.target.value)
        }
        if (val == "detail_dhi") {
            setDataEditingDhi(e.target.value)
        }
        if (val == "ord") {
            setDataEditingOrd(e.target.value)
        }
        if (val == "live") {
            setDataEditingLive(e.target.value)
        }
    }

    const submiteditedForm = async (e) => {
        e.preventDefault()

        if (id == "") {
            dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: 'ID is required.' } })
            return
        }

        setFormButtonLoading(true)
        await axios.post(`/api/gallery/photo/update`, { id: photoId, detail_eng: dataEditingEng, detail_dhi: dataEditingDhi, ord: dataEditingOrd, live: dataEditingLive, pid: id })
            .then(res => {
                setImageData(res.data)
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Photo detail updated successfully' } })
                setOpenEdit(false);
            })
            .catch((err) => {
                setFormButtonLoading(false)
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                picLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <Grid container spacing={2} sx={{ paddingLeft: 2, marginTop: 2 }}>
                        <Typography gutterBottom variant="h6" sx={{ paddingLeft: 2, borderBottom: 2, borderTop: 2, width: "100%" }} color="primary">
                            Images
                        </Typography>
                        <Grid item xs={12} display="flex" justifyContent="end" alignItems="center">
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={handleOpen}
                            >
                                Add New Pictures
                            </Button>
                        </Grid>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                            {(imageData.map(story => (
                                <Grid item xs={6} md={4} key={story.id}>
                                    <Paper sx={{ display: "flex", justifyContent: "center" }} >
                                        <Box sx={{ margin: 1 }}>
                                            <img src={`${siteUrl}uploads/gallery/${story.pic_year}/${story.pic_month}/thumb/${story.pic}_m${story.ext}`} onClick={e => openEditModal(story.id)} />
                                            <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: 1, marginBottom: 1 }}>
                                                <Chip label={"Order: " + story.ord_pic} variant="outlined" />
                                                <Chip label={(story.live === 1) ? "Live: Yes" : "Live: No"} color={(story.live === 1) ? "success" : "error"}></Chip>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            )))}
                        </Grid >

                        <Modal
                            open={open}
                            onClose={handleClose}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <HighlightOff fontSize="large" color="primary" onClick={handleClose} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>
                                </Box>

                                <Box
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '250' },
                                    }}

                                >
                                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5">Add Images</Typography>
                                        </Grid>
                                        <div>
                                            <FormControl fullWidth>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    sx={{ margin: 1 }}
                                                    color="error"
                                                >
                                                    Select Image
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        hidden
                                                        onChange={selectImageHandler}
                                                        multiple
                                                    />
                                                </Button>
                                                <Typography variant="caption" sx={{ textAlign: "center" }} color="initial">{(imageSelected > 0) ? imageSelected + " image(s) selected" : ""}</Typography>
                                            </FormControl>
                                        </div>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                            <Button type="submit" variant="contained" color="success" endIcon={<Send />} disabled={formButtonLoading}>Upload</Button>
                                        </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>
                        <Modal
                            open={openEdit}
                            onClose={handleCloseEdit}
                            onBackdropClick={handleBackdropClick}
                            disableEscapeKeyDown
                            aria-labelledby="modal-modal-titled"
                            aria-describedby="modal-modal-descriptiond"
                        >
                            <Box sx={style_edit}>
                                <>
                                    {imageEditLoading ? (
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                            <CircularProgress color="primary" />
                                        </Box >
                                    ) : (
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <HighlightOff fontSize="large" color="primary" onClick={handleCloseEdit} sx={{ "&:hover": { color: "#f50057" }, cursor: "pointer" }}></HighlightOff>
                                            </Box>
                                            <Box sx={{ marginTop: 2 }}>
                                                <Grid item xs={12} md={12}>
                                                    <img src={`${siteUrl}uploads/gallery/${imageForEdit.pic_year}/${imageForEdit.pic_month}/${imageForEdit.pic}${imageForEdit.ext}`} />
                                                </Grid>
                                                <Box>
                                                    <div>English</div>
                                                    <textarea name="" className="mf_texts" cols="30" value={dataEditingEng} rows="2" onChange={(e) => changeValue(e, "detail_en")}></textarea>
                                                </Box>
                                                <Box>
                                                    <div>Dhivehi</div>
                                                    <textarea name="" className="thaana_admin mf_texts mf-thaana-text-box" cols="30" value={dataEditingDhi} rows="3" onChange={(e) => changeValue(e, "detail_dhi")}></textarea>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <div>Order: <input className="mf_texts" type="text" value={dataEditingOrd} onChange={(e) => changeValue(e, "ord")} /></div>
                                                    <div>Live: <select className="mf_texts" value={dataEditingLive} onChange={(e) => changeValue(e, "live")}>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    </div>
                                                </Box>
                                                <Grid sx={{ marginTop: 2 }} item xs={12} display="flex" justifyContent="center" alignItems="center">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submiteditedForm}
                                                    >
                                                        Update
                                                    </Button>
                                                </Grid>
                                            </Box>
                                        </>
                                    )}
                                </>
                            </Box>
                        </Modal>

                    </Grid>
                )
            }
        </>
    )
}

export default PhotoGalleryEdit