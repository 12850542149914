import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Button, Card, CardContent, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { Collections, Grain, Send, SupervisorAccount } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useValue } from '../../components/context/ContextProvider';
import PhotoGalleryEdit from './PhotoGalleryEdit';
import axios from '../../util/axios';


const EditGallery = () => {

    const params = useParams();
    const { data, isLoading, error } = useFetch(`/api/gallery/photo/edit/${params.id}`)
    const [formButtonLoading, setFormButtonLoading] = useState(false)
    const [titleEngvalue, setTitleEngValue] = useState()
    const [titleDhivalue, setTitleDhiValue] = useState()
    const [detailDhivalue, setDetailDhiValue] = useState()
    const [detailEngvalue, setDetailEngValue] = useState()
    const [dataLive, setDataLive] = useState(0)
    const {
        state: { siteUrl },
        dispatch
    } = useValue()

    const navigate = useNavigate()


    useEffect(() => {
        setTitleEngValue(data.title_en)
        setTitleDhiValue(data.title_dhi)
        setDetailDhiValue(data.detail_dhi)
        setDetailEngValue(data.detail_en)
        setDataLive(data?.live)
    }, [data])

    const handleClick = async () => {
        setFormButtonLoading(true)
        await axios.post(`/api/gallery/update`, {
            title_en: titleEngvalue,
            title_dhi: titleDhivalue,
            detail_dhi: detailDhivalue,
            detail_en: detailEngvalue,
            live: dataLive,
            id: data.id
        })
            .then(res => {
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Gallery updated successfully' } })
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box role="presentation" sx={{ padding: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/galleries"
                                        >
                                            <Collections sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Galleries
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            {(data.gallery_type == 1) ? "Photo Gallery" : "Video Gallery"}

                                        </Typography>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <Grain sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {data.title_en}
                                        </Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ padding: 2 }} color="success">
                                        {data.title_en}
                                    </Typography>
                                    <Grid container spacing={2} sx={{ paddingLeft: 2 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography gutterBottom variant="h6" component="div" color="primary">English</Typography>
                                            <Box>
                                                <input type="text" className="mf_texts" value={titleEngvalue} onChange={(e) => setTitleEngValue(e.target.value)} />
                                            </Box>
                                            <Box>
                                                <textarea name="" className="mf_texts" cols="30" rows="10" value={detailEngvalue} onChange={(e) => setDetailEngValue(e.target.value)} ></textarea>
                                            </Box>
                                            <Box>
                                                <Typography gutterBottom variant="h6" component="div" color="primary">Live</Typography>
                                                <select className="mf_texts full_width" value={dataLive} onChange={(e) => setDataLive(e.target.value)}>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography gutterBottom variant="h6" component="div" color="primary">Dhivehi</Typography>
                                            <Box>
                                                <input type="text" className="thaana_admin mf-thaana-text-box mf_texts" value={titleDhivalue} onChange={(e) => setTitleDhiValue(e.target.value)} />
                                            </Box>
                                            <Box>
                                                <textarea name="" className="thaana_admin mf-thaana-text-box mf_texts" cols="30" rows="10" value={detailDhivalue} onChange={(e) => setDetailDhiValue(e.target.value)} ></textarea>
                                            </Box>
                                        </Grid>
                                    </Grid >
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                        <Button type="submit" variant="contained" color="success" endIcon={<Send />} onClick={handleClick} disabled={formButtonLoading}>Update</Button>
                                    </Grid>
                                </Box>
                                {(data.gallery_type == 1) ? <PhotoGalleryEdit /> : "Video"}
                            </CardContent>
                        </Card>
                    </>
                )
            }
        </>
    )
}

export default EditGallery