import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Button, Card, CardContent, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useFetch from '../../util/useFetch'
import { Collections, Grain, Send, SupervisorAccount } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useValue } from '../../components/context/ContextProvider';
import AdvertPicEdit from './AdvertPicEdit';
import axios from '../../util/axios';


const EditGallery = () => {

    const params = useParams();
    const { data, isLoading, error } = useFetch(`/api/ads/edit/${params.id}`)
    const [formButtonLoading, setFormButtonLoading] = useState(false)
    const [name, setName] = useState()
    const [adLink, setAdLink] = useState()
    const [adLocation, setAdLocation] = useState()
    const [dataLive, setDataLive] = useState(0)
    const {
        state: { siteUrl },
        dispatch
    } = useValue()

    const navigate = useNavigate()


    useEffect(() => {
        setName(data[0]?.name)
        setAdLink(data[0]?.link)
        setAdLocation(data[0]?.loc)
        setDataLive(data[0]?.live)
    }, [data])

    const handleClick = async () => {
        setFormButtonLoading(true)
        await axios.post(`/api/ads/update`, {
            name: name,
            link: adLink,
            loc: adLocation,
            live: dataLive,
            id: params.id
        })
            .then(res => {
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'success', message: 'Advert updated successfully' } })
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 419) {
                    navigate('/login')
                }
                dispatch({ type: "UPDATE_ALERT", payload: { open: true, severity: 'error', message: err.response.data.message } })
                setFormButtonLoading(false);
            })
        setFormButtonLoading(false)
    }

    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Card>
                            <CardContent>
                                <Box role="presentation" sx={{ padding: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/adverts"
                                        >
                                            <Collections sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Adverts
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <Grain sx={{ mr: 0.5 }} fontSize="inherit" />
                                            {data[0]?.name}
                                        </Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ padding: 2 }} color="success">
                                        {data[0]?.name}
                                    </Typography>
                                    <Grid container spacing={2} sx={{ paddingLeft: 2 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography gutterBottom variant="h6" component="div" color="primary">Name</Typography>
                                            <Box>
                                                <input type="text" className="mf_texts" value={name} onChange={(e) => setName(e.target.value)} />
                                            </Box>
                                            <Typography gutterBottom variant="h6" component="div" color="primary">Link</Typography>
                                            <Box>
                                                <input type="text" className="mf_texts" cols="30" rows="10" value={adLink} onChange={(e) => setAdLink(e.target.value)} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography gutterBottom variant="h6" component="div" color="primary">Location</Typography>
                                            <Box>
                                                <select name="" id="" className="mf_texts" value={adLocation} onChange={(e) => setAdLocation(e.target.value)}>
                                                    {(data[1]?.map((item) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )))}
                                                </select>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Box sx={{ width: "50%" }}>
                                                    <Typography gutterBottom variant="h6" component="div" color="primary">Hits</Typography>
                                                    <Box>
                                                        <input type="text" className="mf_texts" value={data[0]?.hits} readOnly />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: "50%", marginLeft: 1 }}>
                                                    <Typography gutterBottom variant="h6" component="div" color="primary">Live</Typography>
                                                    <Box display="flex" justifyContent="space-between">
                                                        <select className="mf_texts full_width" value={dataLive} onChange={(e) => setDataLive(e.target.value)}>
                                                            <option value="1">Yes</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid >
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
                                        <Button type="submit" variant="contained" color="success" endIcon={<Send />} onClick={handleClick} disabled={formButtonLoading}>Update</Button>
                                    </Grid>
                                </Box>
                                <AdvertPicEdit />
                            </CardContent>
                        </Card>
                    </>
                )
            }
        </>
    )
}

export default EditGallery