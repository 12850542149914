import React, { createContext, useContext, useEffect, useReducer } from 'react'
import reducer from './reducer';

const initialState = {
    currentUser: null,
    //siteUrl: "http://localhost:8000/",
    siteUrl: "https://maldivesfootball.com/",
    isDarkTheme: false,
    openLogin: false,
    windowLoading: true,
    loadingBar: 0,
    unapprovedComments: 0,
    alert: { open: false, severity: 'info', message: '' },
}

const Context = createContext(initialState)

export const useValue = () => {
    return useContext(Context);
}

const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('currentuser_mf'))
        if (currentUser) {
            dispatch({ type: 'UPDATE_USER', payload: currentUser })
        }
    }, [])
    return (
        <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
    )
}

export default ContextProvider