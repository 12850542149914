import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useParams } from 'react-router-dom';
import useFetch from '../../util/useFetch';

const Others = () => {
    const params = useParams();
    const { data, isLoading, error } = useFetch(`/api/others/${params.id}`)
    return (
        <>
            {
                isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <CircularProgress color="primary" />
                    </Box >
                ) : (
                    <>
                        <Typography gutterBottom variant="h5" component="div">
                            {data}
                        </Typography>
                    </>
                )}
        </>
    )
}

export default Others